//@font-face {
//  font-family: 'Poppins';
//  src: local('Poppins Light'), local('Poppins-Light'),
//  url('../fonts/subset-Poppins-Light.woff2') format('woff2'),
//  url('../fonts/subset-Poppins-Light.woff') format('woff');
//  font-weight: 300;
//  font-style: normal;
//  font-display: swap;
//}
/* desactive pour le backo */
/*
html body:not([class*="breakpoint"]) {

  .roboto {
    font-family: 'Roboto', sans-serif;
  }
  .pressstyle {
    font-family: 'Press Style', fantasy, sans-serif;
  }

}
*/

@font-face {
  font-family: 'Poppins Medium';
  src: local('Poppins Medium'), local('Poppins-Medium'),
  url('../fonts/Poppins-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins Light';
  src: local('Poppins Light'), local('Poppins-Light'),
  url('../fonts/Poppins-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins Regular';
  src: local('Poppins Regular'), local('Poppins-Regular'),
  url('../fonts/Poppins-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins Bold';
  src: local('Poppins Bold'), local('Poppins-Bold'),
  url('../fonts/Poppins-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins Extrabold';
  src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'),
  url('../fonts/Poppins-ExtraBold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins Semibold';
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
  url('../fonts/Poppins-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}