/* Boutons */

.btn {
  box-shadow: none;
}
.btn-u {
  border:0;
  font-size:1.8rem;
  line-height: 1.2rem;
  cursor:pointer;
  padding:1.8rem 3.4rem;
  position:relative;
  background-color:$brand-primary !important;
  display:inline-block;
  color:#fff !important;
  text-decoration:none !important;
  border-radius: 5rem;
  text-transform:uppercase;
  transition:all 0.3s ease;
  &:hover {
    color:#fff;
    background:darken($brand-primary,5%) !important;
    text-decoration:none;
    transition:all 0.4s ease-in-out;
  }

  &.marron {
    background-color:#372c26 !important;
    &:hover {
      background:darken(#372c26,5%) !important;
    }
  }

  &.blanc {
    background-color:transparent !important;
    border:1px solid $text-color;
    color: $text-color !important;
    &:hover {
      background:transparent !important;
      border:1px solid $brand-primary;
      color: $brand-primary !important;
    }
  }
}
.btn-u-small {
  padding:0.8rem 1.8rem;
  font-size: 0.9rem;
}
.btn-u-lg {
  font-size:1.8rem;
  padding:1rem 2.5rem;
}
a.btn-u {
  vertical-align:middle;
}
a.btn-u-lg {
  font-size:1.8rem;
  padding:0.6rem 2.5rem;
}
.boutons {
  text-align: center;
}
.bouton {
  display: inline-block;
  vertical-align: middle;
  padding:0.5rem;
}