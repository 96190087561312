.image_container {
  position:relative;
  max-width:100%;
  margin-bottom: 0;
  a {
    position: relative;
    font-style: normal;
    display:block;
    overflow: hidden;
    .icone {
      position: absolute;
      left: 50%;
      top: 50%;
      width:5rem;
      height:50px;
      transform:translateX(-50%) translateY(-50%);
      z-index: 90;
      visibility:hidden;
      &::after {
        position: absolute;
        left: 50%;
        top: 50%;
        font-family: "kfont";
        transform:translateX(-50%) translateY(-50%);
        font-size: 2.4rem;
        color: #FFFFFF;
        text-align: center;
      }
      &.url {
        &::after {
          content:"\EA08";
        }
      }
      &.zoom {
        &::after {
          content:"\EA24";
        }
      }
    }
    &:hover {
      text-decoration: none;
      .icone {
        visibility: visible;
      }
      img {
        transform: scale(1.02);
      }
    }
    img {
      transition: all 0.8s ease-in-out 0s;
    }
  }
  img {
    max-width: 100%;
    height:auto;
    width: 100%;
  }
  .caption {
    text-shadow:none !important;
    text-align:left;
    font-size:1.1rem;
  }
  &.float_below {
    margin-top:3rem;
  }
  &.float_above {
    margin-bottom:3rem;
  }
  &.float_left {
    margin-right:3rem;
    margin-bottom:3rem;
  }
  &.float_right {
    margin-left:3rem;
    margin-bottom:3rem;
  }
}