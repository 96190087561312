/* Barre de cookies */

.privacypolicies-com---nb-simple {
  width: 100% !important;
  max-width: 100% !important;
  background-color: rgba($text-color,0.75) !important;
  text-align: center !important;
  .cc-nb-title,
  .cc-nb-text{
    color: #FFFFFF !important;
    font-size: 16px !important;
  }
  .cc-nb-okagree {
    background-color:$brand-primary !important;
    border-radius: 0;
  }
  .cc-nb-reject {
    background-color:grey !important;
    border-radius: 0;
  }
  .cc-nb-changep {
    border-radius: 0;
  }
}
.privacypolicies-com---nb .cc-nb-main-container {
  padding: 1.5rem !important;
}
.privacypolicies-com---nb .cc-nb-text {
  line-height: 16px;
  font-size: 14px !important;
}

.privacypolicies-com---palette-light .cc-cp-foot-save {
  background-color:$brand-primary !important;
}

.privacypolicies-com---palette-light .cc-pc-head-lang select:focus {
  box-shadow: 0 0 0 2px $brand-primary !important;
}