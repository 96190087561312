.popup-info {
  background-color: #D22B32;
  color: #FFF;
  font-size: 1.8rem;
  line-height: 1.2;
  position: relative;
  padding-left: 6rem;
  padding-right: 5.5rem;
  a {
    color: #FFF;
  }
  >div {
    padding: 1.5rem;
  }
  .button-close {
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
    padding: 0;
    width: 2.6rem;
    height: 2.6rem;
    color: #FFFFFF;
    white-space: nowrap;
    display: block;
    cursor: pointer;
    font-size: 1.2rem;
    img {
      width: 2.6rem;
      height: 2.6rem;
    }
  }
  &::before {
    content:"";
    width: 6rem;
    height: 6rem;
    background-image: url("/templates/theme-site/img/theme/picto-info.png");
    background-repeat: no-repeat;
    background-size: contain;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }
}