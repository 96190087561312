/**********/
/* Footer */
/**********/
.footer {
  position: relative;
  background-image: url("/templates/theme-site/img/theme/vague-footer.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  z-index: 1;
  @media (max-width: 991px) {
    background-image: none;
    background-color: $brand-primary !important;
  }
  /* Main footer */
  .footer_main {
    color:$text-lighter;
    padding:8rem;
    padding-top: 18rem;
    padding-bottom: 0;
    font-size: 1.4rem;
    @media (max-width: 991px) {
      padding-top: 8rem;
    }
    @media (max-width: 767px) {
      padding: 5rem;
    }
    >.container {
      >.row {
        @media (max-width: 991px) {
          flex-direction: column;
        }
      }
    }

    .adresse p,
    .reseaux_sociaux {
      margin-top: 2rem;
      display: flex;
      align-items: center;
      @media (max-width: 991px) {
        justify-content: center;
      }
      a {
        color: #FFF;
        margin-right: 0.75rem;
        transition: all 0.2s ease;
        display: block;
        &:hover {
          color: $brand-primary;
          text-decoration: none;
          transform: translateY(-0.25rem);
        }
        .icon {
          font-size: 3rem;
          line-height: 3rem;
        }
      }
    }

    .adresse p {
      @media (max-width: 991px) {
        margin-top: 1rem;
      }
    }

    .col-footer {
      padding-right:10rem;
      margin-right:0;
      padding-left: 0;
      margin-bottom: 3rem;
      @media (max-width: 991px) {
        width: 100%;
        padding-right:0;
        align-items: center;
        text-align: center;
        display: flex;
        flex-direction: column;
      }
    }

    .col-adresse {
      position: relative;
      @media (max-width: 991px) {
        border-bottom: 1px solid rgba(#FFF,0.15);
        padding-bottom: 3rem;
      }
      &::after {
        content: "";
        position: absolute;
        right: 5rem;
        height: 65%;
        width: 1px;
        background-color: rgba(#FFF,0.43);
        top: 50%;
        transform: translateY(-50%);
        @media (max-width: 991px) {
          display: none;
        }
      }
      .title-block {
        &::before {
          width: 5.2rem;
          height: 5.2rem;
          position: relative;
          content: "";
          background-image: url("/templates/theme-site/img/theme/picto-mairie.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
        span {
          transform: translateY(0.5rem);
        }
      }
      a {
        display: block;
        transition: all 0.3s ease;
        &:hover {
          transform: translateX(0.5rem);
          text-decoration: none;
        }
      }
      .adresse {
        margin-bottom: 3rem;
      }
      .adresse-postale {
        font-size: 2rem;
        line-height: 1.2;
      }
      .telephone {
        margin-bottom: .5rem;
        @media (max-width: 991px) {
          margin-bottom: 1rem;
        }
        a {
          font-size: 2rem;
          display: flex;
          align-items: center;
          color: #FFF;
          @media (max-width: 991px) {
            flex-direction: column;
          }
          &::before {
            content:"\EA41";
            font-family: "kfont";
            width: 4.1rem;
            height: 4.1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #FFF;
            color: $brand-primary;
            margin-right: 0.7rem;
            border-radius: 50%;
          }
        }
      }
      .contact-mairie {
        a {
          font-size: 2rem;
          display: flex;
          align-items: center;
          color: #FFF;
          @media (max-width: 991px) {
            flex-direction: column;
          }
          &::before {
            content:"\EA40";
            font-family: "kfont";
            width: 4.1rem;
            height: 4.1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #FFF;
            color: $brand-primary;
            margin-right: 0.7rem;
            border-radius: 50%;
          }
        }
      }
    }

    .col-contact {
      position: relative;
      @media (max-width: 991px) {
        border-bottom: 1px solid rgba(#FFF,0.15);
        padding-bottom: 3rem;
      }
      &::after {
        content: "";
        position: absolute;
        right: 5rem;
        height: 65%;
        width: 1px;
        background-color: rgba(#FFF,0.43);
        top: 50%;
        transform: translateY(-50%);
        @media (max-width: 991px) {
          display: none;
        }
      }
      .title-block {
        &::before {
          width: 7rem;
          height: 5.3rem;
          position: relative;
          content: "";
          background-image: url("/templates/theme-site/img/theme/picto-horaires.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }
      .items-contact {
        @media (max-width: 575px) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .item {
          margin-bottom: 0.5rem;
          @media (max-width: 575px) {
            margin-bottom: 1rem;
          }
          a {
            display: flex;
            align-items: center;
            color: #FFF;
            transition: all 0.3s ease;
            @media (max-width: 575px) {
              flex-direction: column;
            }
            .icon {
              margin-right: 0.5rem;
              font-size: 16px;
              line-height: 1;
              @media (max-width: 575px) {
                margin-right: 0;
              }
            }
            &:hover {
              color: $brand-primary;
              text-decoration: none;
            }
            span {
              line-height: 1;
            }
          }
        }
      }
      .horaires {
        font-size: 2rem;
        line-height: 1.2;
      }
    }

    .col-partenaires {
      padding-right: 0;
      margin-right: 0;
      .layout_full {
        display: flex;
        align-items: center;
        .item {
          margin-right: 1rem;
          width: 8rem;
          height: 8rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #FFF;
          transition: all 0.3s ease;
          &.clickable {
            cursor: pointer;
            &:hover {
              transform: translateY(-0.5rem);
            }
          }
          .logo {
            height: 100%;
            .value {
              height: 100%;
              ul {
                margin: 0;
                padding: 0;
                list-style-type: none;
                height: 100%;
                li {
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  img {
                    max-width: 100%;
                    max-height: 100%;
                  }
                }
              }
            }
          }
          .nom, .url {
            display: none;
          }
        }
      }
    }

    .title-block {
      font-size: 2.2rem;
      text-transform: uppercase;
      margin-bottom: 3rem;
      position: relative;
      display: flex;
      align-items: center;
      min-height: 5.2rem;
      line-height: 1.1;
      @media (max-width: 991px) {
        flex-direction: column;
        margin-bottom: 1.5rem;
      }
      &::before {
        margin-right: 0.7rem;
        @media (max-width: 991px) {
          margin-right: 0;
          margin-bottom: 0.5rem;
        }
      }
    }

  }

  /* Subfooter */
  .footer_subfooter {
    color:$text-lighter;
    padding:2.5rem;
    font-size: 1.4rem;
    position: relative;
    .wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }
    span {
      display: block;
      @media (max-width: 767px) {
        padding: 0.25rem;
      }
    }
    ul {
      margin: 0;
      padding: 0;
    }
    .mod_customnav {
      ul {
        margin:0;
        padding:0;
        display: flex;
        align-items: center;
        @media (max-width: 767px) {
          flex-direction: column;
        }
        li {
          list-style-type: none;
          display: flex;
          align-items: center;
          @media (max-width: 767px) {
            flex-direction: column;
            padding: 0.25rem;
          }
          &::before {
            content:"-";
            margin-left:0.5rem;
            margin-right:0.5rem;
            @media (max-width: 767px) {
              display: none;
            }
          }
          a {
            color: #FFF;
            transition: all 0.3s ease;
            &:hover {
              color: #FFF;
              text-decoration: none;
              transform: translateX(0.5rem);
            }
          }
          strong {
            font-weight: normal;
          }
        }
      }
    }
  }

  /* Signature */
  .signature {
    position: absolute;
    z-index: 9;
    white-space: nowrap;
    right: 7rem;
    padding: 0;
    line-height: 2.5rem;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 575px) {
      position: relative;
      top: auto;
      right: auto;
      transform: none;
      text-align: center;
    }
    a {
      transition: all 0.3s ease;
      font-size: 10px;
      color: #FFF;
      display: block;
      &:hover {
        color: #FFF;
        text-decoration: none;
        transform: translateY(-0.5rem);
      }
    }
  }
}

.social_block {
  .layout_full {
    .item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .field {
        a {
          display: flex;
          width: 4.5rem;
          height: 4.5rem;
          background-color:#FFF;
          color: $brand-primary;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          &:hover {
            color: $brand-primary;
          }
          &::before {
            font-family: "kfont";
            font-size: 2rem;
          }
        }
        &.facebook a::before {
          content:"\EA01";
        }
        &.instagram a::before {
          content:"\EA02";
        }
        &.linkedin a::before {
          content:"\EA03";
        }
        &.pinterest a::before {
          content:"\EA04";
        }
        &.twitter a::before {
          content:"\EA05";
        }
        &.youtube a::before {
          content:"\EA06";
        }
      }
    }
  }
}