/* Simple téléchargement */
.ce_download,
.enclosure {
  a {
    .icon {
      vertical-align:middle;
      margin-right:0.5rem;
      font-size: 2em;
    }
  }
  .size {
    margin-left:0.5rem;
  }
}

/* Téléchargement multiple */
.ce_downloads {
  ul {
    padding-left:0;
    li {
      list-style-type:none;
      margin-bottom:0.5rem;
    }
  }
  a {
    .icon {
      vertical-align:middle;
      margin-right:0.5rem;
      font-size: 2em;
    }
  }
  .size {
    margin-left:0.5rem;
  }
}