/* Mobile menu */
.mobile_menu {
  display: block;
  .bouton_menu_mobile {
    background-color:transparent;
    color: $brand-primary;
    display: block;
    z-index: 10000000;
    left: 1.5rem;
    position: absolute;
    top: 1.5rem;
    width: 10rem;
    height: 10rem;
    left:0;
    top:0;
    &:hover {
      color: $brand-primary;
    }
    &.open {
      width:100%;
      background: $text-color;
    }
    .icon-bars {
      background: $text-color;
      display: block;
      height: 6.7rem;
      width: 6.7rem;
      margin-left: 1.5rem;
      margin-top: 1.5rem;
      padding-top: 1.1rem;
      text-align: center;
      font-size: 3.54rem;
    }
  }
  &.ouvert {
    .bouton_menu_mobile {
      position: fixed;
    }
  }
  .menu-side {
    background-color: $text-color;
    display: block;
    width: 100%;
    z-index: 9999999;
    height: 100%;
    left: -100%;
    position: fixed;
    top: 0;
    overflow: auto;
    .barre_menu_mobile {
      background-color: $text-color;
      height: 10rem;
      width: 100%;
    }
    .menu_mobile {
      text-align: center;
      ul {
        margin: 0;
        position: relative;
        padding: 0;
        & > li {
          background-color: #fff;
          border-bottom: 1px solid $text-color;
          margin: 0;
          list-style-type: none;
          & > a {
            color: $text-color;
            display: block;
            font-size: 2rem;
            font-family: "Poppins Bold", sans-serif;
            padding: 0;
            text-transform: uppercase;
            padding: 2rem;
            border-left:5px solid $text-color;
            &:hover {
              text-decoration: none;
              color: $text-color !important;
            }
          }
          ul {
            position: absolute;
            display: none;
            left: 100%;
            width: 100%;
            top: 0;
          }
        }
      }
      #menu_mobile_back {
        color: $text-color;
        font-size: 1.5rem;
        text-transform: none;
      }
    }
  }
}

$i: 1;
@while $i < 8 {
  .mobile_menu .menu-side .menu_mobile ul > li.rub#{$i} a { border-left: 5px solid map-get($couleurs, $i) }
  body.rub#{$i} .mobile_menu .bouton_menu_mobile { color: map-get($couleurs, $i) }
  $i: $i + 1;
}