/* Calendrier */
table {
  &.calendar, &.minicalendar {
    th {
      &.head {
        text-align:center;
      }
    }
    .days {
      &.active {
        background-color:$brand-primary-alt;
        &today {
          background-color:$brand-primary;
        }
      }
    }
    a:hover {
      color:#000000;
    }
  }
}