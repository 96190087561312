@mixin title-global {
  word-wrap: normal;
  hyphens: none;
  line-height: 1;
  font-family: "Poppins Bold", sans-serif;
}

@mixin h1 {
  font-size: 3.6rem;
  margin-top:0;
  margin-bottom: 3rem;
}

@mixin h2 {
  font-size: 3rem;
  margin-top:0;
  margin-bottom: 2.5rem;
}

@mixin h3 {
  font-size: 2.4rem;
  margin-top:0;
  margin-bottom: 2rem;
}

@mixin h4 {
  font-size: 1.8rem;
  margin-top:0;
  margin-bottom: 2rem;
}

@mixin h5 {
  font-size: 1.4rem;
  margin-top:0;
  margin-bottom: 1.5rem;
}

@mixin h6 {
  font-size: 1.2rem;
  margin-top:0;
  margin-bottom: 1rem;
}