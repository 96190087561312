/* Zone bandeau de page */
.zone-bandeau {
  .mod_article {
    margin-bottom:0;
  }
  #swiper_diaporama_bandeau {
      &.no-scroll {
        .swiper-button {
          display: none;
        }
      }
  }
}

.bandeau-de-page {
  position: relative;
  &::after {
    content: "";
    width: calc(100% + 1px);
    height:15.5rem;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    position: absolute;
    bottom: 0;
    background-image: url("/templates/theme-site/img/theme/bandeau-rub1.svg");
    @media (max-width: 991px) {
      height:5rem;
    }
  }
  &::before {
    content:"";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 65%;
    z-index: 1;
    background: -moz-linear-gradient(top, rgba(map-get($couleurs, 1), 0.5) 0%, rgba(map-get($couleurs, 1), 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(map-get($couleurs, 1), 0.5) 0%, rgba(map-get($couleurs, 1), 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(map-get($couleurs, 1), 0.5) 0%, rgba(map-get($couleurs, 1), 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
  .wrap-title {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    color:$text-lighter;
    z-index: 1;
    /*
    @media (max-width: 991px) {
      transform: translateY(-75%);
    }
    */
    @media (max-width: 767px) {
      padding: 3rem;
    }
    /*
    @media (max-width: 480px) {
      transform: translateY(-70%);
    }
    */
    a {
      color:$text-lighter;
    }
    .page-title {
      font-size: 5.4rem;
      text-transform: uppercase;
      font-family: "Poppins SemiBold";
      margin: 0;
      margin-bottom: 1rem;
      @media (max-width: 767px) {
        font-size: 3rem !important;
      }
      @media (max-width: 480px) {
        font-size: 2.5rem !important;
      }
    }
  }
  .breadcrumbs {
    padding: 0;
    background-color: transparent;
    border: 0;
    font-size:1.4rem;
    a {
      color:$text-lighter;
    }
  }
  .mod_pageimage {
    .image_container {
      img {
        @media (max-width: 991px) {
          object-fit: cover;
          height: 30rem;
        }
      }
    }
  }
}

$i: 1;
@while $i < 8 {
  body.rub#{$i} .bandeau-de-page::after {
    background-image: url("/templates/theme-site/img/theme/bandeau-rub#{$i}.svg");
  }
  body.rub#{$i} .bandeau-de-page::before {
    background: -moz-linear-gradient(top, rgba(map-get($couleurs, $i), 0.5) 0%, rgba(map-get($couleurs, $i), 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(map-get($couleurs, $i), 0.5) 0%, rgba(map-get($couleurs, $i), 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(map-get($couleurs, $i), 0.5) 0%, rgba(map-get($couleurs, $i), 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  $i: $i + 1;
}


body.rub7 .bandeau-de-page::before {
  background: -moz-linear-gradient(top, rgba(map-get($couleurs, 7), 0.25) 0%, rgba(map-get($couleurs, 7), 0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(map-get($couleurs, 7), 0.25) 0%, rgba(map-get($couleurs, 7), 0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(map-get($couleurs, 7), 0.25) 0%, rgba(map-get($couleurs, 7), 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
body.rub5 .bandeau-de-page::before {
  background: -moz-linear-gradient(top, rgba(map-get($couleurs, 5), 0.25) 0%, rgba(map-get($couleurs, 5), 0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(map-get($couleurs, 5), 0.25) 0%, rgba(map-get($couleurs, 5), 0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(map-get($couleurs, 5), 0.25) 0%, rgba(map-get($couleurs, 5), 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
body.rub4 .bandeau-de-page::before {
  background: -moz-linear-gradient(top, rgba(map-get($couleurs, 4), 0.25) 0%, rgba(map-get($couleurs, 4), 0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(map-get($couleurs, 4), 0.25) 0%, rgba(map-get($couleurs, 4), 0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(map-get($couleurs, 4), 0.25) 0%, rgba(map-get($couleurs, 4), 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
body.rub3 .bandeau-de-page::before {
  background: -moz-linear-gradient(top, rgba(map-get($couleurs, 3), 0.25) 0%, rgba(map-get($couleurs, 3), 0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(map-get($couleurs, 3), 0.25) 0%, rgba(map-get($couleurs, 3), 0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(map-get($couleurs, 3), 0.25) 0%, rgba(map-get($couleurs, 3), 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.body-wrapper {
  position: relative;
  overflow: hidden;
  &::before {
    content:"";
    position: absolute;
    left: 0;
    bottom: -121rem;
    width: 50rem;
    height: 250rem;
    background-image: url("/templates/theme-site/img/theme/fond-page.svg");
    background-size: cover;
    background-position: right bottom;
    z-index: 0;
  }
  .content-wrapper {
    z-index: 3;
    position: relative;
    background-color: transparent;
    min-height: 55rem;
  }
}

.block {
  float: none;
  clear: both;
}

/*
*******************************************************
MENU SOUS-PAGES
*******************************************************
*/

.menu-sous-pages {

  padding-top:2rem;
  padding-bottom:3rem;
  padding-left:0;
  padding-right:0;

  .titre-paragraphe {

    margin-left:0;
    margin-right:0;

  }

  ul {

    padding-left:0;

    li {

      display: none;

      &.page-accueil {

        display:none !important;

      }

      &.active, &.trail {

        display:block !important;

      }
      >a.trail {
        display: none;
      }
      &.active {
        >a.active {
          display: none;
        }
        >ul>li {
          display:block !important;
          >a {
            display:flex !important;
          }
        }
      }

    }

    li.active {
      >ul {
        display: flex;
        list-style-type: none;
        align-items: stretch;
        flex-wrap: wrap;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        >li {
          padding: 1.5rem;
          width: 25%;
          @media (max-width: 767px) {
            width: 33.3334%;
          }
          @media (max-width: 580px) {
            width: 50%;
          }
          @media (max-width: 450px) {
            width: 1000%;
          }
          >a {
            display: flex !important;
            align-items: center;
            justify-content: center;
            background-color: red;
            color: $text-lighter;
            height: 100%;
            padding: 3rem;
            text-align: center;
            font-weight: 500;
            font-size: 1.8rem;
            @include transition;
            &:hover {
              @include push-right;
              text-decoration: none;
            }
          }
        }
      }
    }

  }

}

$i: 1;
@while $i < 8 {
  body.rub#{$i} .menu-sous-pages ul li.active >ul >li > a { background-color: map-get($couleurs, $i) }
  $i: $i + 1;
}

.souligne {
  position: relative;
  padding-bottom: 1rem;
  display: block;
  &::after {
    content: "";
    width: 4rem;
    height: 0.3rem;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: $brand-primary;
  }
}
.fond-couleur {
  .souligne {
    &::after {
      background-color: #FFF !important;
    }
  }
}

.content-wrapper h1 {
  @extend .souligne;
}

$i: 1;
@while $i < 8 {
  body.rub#{$i} .content-wrapper .block .souligne::after { background-color: map-get($couleurs, $i) }
  $i: $i + 1;
}

/*
*******************************************************
LISTE CRCM
*******************************************************
*/

.liste-crcm {
  .wrap-item {
    @media (max-width: 767px) {
      margin-bottom: 1.5rem;
    }
  }
  .item {
    .year {
      font-size: 3.2rem;
      font-family:"Poppins SemiBold" ;
      margin-top: 3rem;
      margin-bottom: 1.5rem;
      @media (max-width: 480px) {
        text-align: center;
      }
    }
    .file {
      display: flex;
      align-items: flex-start;
      font-size: 1.8rem;
      transition: all 0.3s ease;
      cursor: pointer;
      @media (max-width: 480px) {
        flex-direction: column;
        align-items: center;
      }
      .date {
        display: none;
      }
      &::before {
        content:"\EA30";
        font-family: "kfont";
        font-size: 3.8rem;
        margin-right: 1.5rem;
        @media (max-width: 480px) {
          margin-right: 0;
        }
      }
      .libelle {
        margin-right: 1.5rem;
        @media (max-width: 480px) {
          text-align: center;
          margin-right: 0;
        }
        a {
          color: $text-color;
          &:hover {
            text-decoration: none;
          }
        }
        .text {
          margin-right: 0.5rem;
        }
        .doc_items {
          display: flex;
          flex-direction: column;
          padding-left: 1rem;
          border-left:1px dotted rgba(0,0,0,0.35);
          margin-bottom: 1.5rem;
          @media (max-width: 480px) {
            padding-left: 0;
            border-left:none;
          }
          .doc_item {
            transition: all 0.3s ease;
            cursor: pointer;
            &:hover {
              transform: translateX(0.5rem);
            }
            .title {
              font-size: 1.6rem;
            }
          }
        }
      }
      &.simple {
        align-items: center;
        &:hover {
          transform: translateX(0.5rem);
        }
        .doc_item {
          .title {
            display: none;
          }
        }
      }
    }
  }
}


.filtres-crcm {
  .categorie {
    label {
      display: none;
    }
    ul {
      display: flex;
      align-items: center;
      padding: 0;
      border-bottom: 1px solid rgba(0,0,0,0.15);
      li {
        list-style-type: none;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        &.comptes-rendus {
          padding-left: 3rem;
        }
        a {
          font-size: 3rem;
          margin-top: 0;
          margin-bottom:0;
          position: relative;
          padding: 1rem;
          padding-left: 3rem;
          padding-right: 3rem;
          display: block;
          text-transform: uppercase;
          font-family: "Poppins Bold",sans-serif;
          color: $text-color;
          transition: all 0.3s ease;
          border:1px solid rgba($text-color,0.15);
          border-bottom: 0;
          position: relative;
          @media (max-width: 560px) {
            line-height: 1.1;
          }
          &::after {
            background-color: $brand-primary;
            content: "";
            width: 4rem;
            height: .3rem;
            position: absolute;
            left: 0;
            bottom: 0;
            display: none;
          }

          background-color: #eef6f9;

          -webkit-border-top-left-radius: 1rem;
          -webkit-border-top-right-radius: 1rem;
          -moz-border-radius-topleft: 1rem;
          -moz-border-radius-topright: 1rem;
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;

          &:hover, &.active {
            text-decoration: none;
            color: $brand-primary;
            background-color: #FFF;
          }
          &.active {
            pointer-events: none;
            &::after {
              content: "";
              height: 1px;
              background-color: #FFF;
              position: absolute;
              bottom: -1px;
              left:0;
              width: 100%;
              display: block;
            }
          }
        }
      }
      .bulletins-municipaux,.arrêtés {
        display: none;
      }
    }
  }
}

/*
*******************************************************
BLOC MENU PAGES
*******************************************************
*/

.bloc-menu-pages {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  .bloc-menu {
    width: 33.3334%;
    padding: 3rem;
    @media (max-width: 680px) {
      width: 50%;
    }
    @media (max-width: 480px) {
      width: 100%;
    }
    .wrap-item {
      position: relative;
      .visuel {
        position: relative;
        background-color: $brand-primary;
        margin-bottom: 0.5rem;
        img {
          max-width: 100%;
          height: auto;
        }
        &::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 0;
          opacity: 0;
          transition: all 0.3s ease;
          z-index: 1;
        }
        &::after {
          content: "+";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 5.2rem;
          height: 5.2rem;
          transition: all 0.3s ease;
          z-index: 1;
          background-color: #FFF !important;
          border-radius: 50%;
          font-size: 3.7rem;
          font-family: "Poppins Light", sans-serif;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
        }
      }
      .url {
        position: relative;
        transition: all 0.3s ease;
        span {
          z-index: 1;
          transition: all 0.3s ease;
          text-transform: uppercase;
          position: relative;
          padding-bottom: 1rem;
          margin-bottom: 0;
          display: block;
          font-size: 3rem;
          margin-bottom: 0;
          color: $text-color;
          font-family: "Poppins Bold",sans-serif;
          line-height: 1.1;
          &::after {
            content: "";
            width: 4rem;
            height: .3rem;
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }
      }
    }
    &.clickable {
      .wrap-item {
        cursor: pointer;
        .url {
          &:hover {
            text-decoration: none;
          }
        }
        &:hover {
          .visuel {
            &::before {
              height: 100%;
              opacity: 0.8;
            }
            &::after {
              opacity: 1;
            }
          }
        }
      }
    }
    &.no-img {
      &.clickable {
        .visuel {
          &::after {
            opacity: 1;
            background-color: rgba(#FFF, 0.35) !important;
          }
          &:hover {
            &::after {
              transform: translateY(calc(-50% - 1rem)) translateX(-50%);
              background-color: rgba(#FFF, 1) !important;
            }
          }
        }
      }
    }
  }
}

$i: 1;
@while $i < 8 {
  body.rub#{$i} .bloc-menu-pages .bloc-menu.clickable .wrap-item:hover .visuel::before { background-color: map-get($couleurs, $i) }
  body.rub#{$i} .bloc-menu-pages .bloc-menu.clickable .wrap-item .visuel::after { color: map-get($couleurs, $i) }
  body.rub#{$i} .bloc-menu-pages .bloc-menu.no-img .wrap-item .visuel { background-color: map-get($couleurs, $i) }
  body.rub#{$i} .bloc-menu-pages .bloc-menu.no-img .wrap-item .visuel::before { background-color: map-get($couleurs, $i) }
  body.rub#{$i} .bloc-menu-pages .bloc-menu.clickable .wrap-item .url span::after { background-color: map-get($couleurs, $i) }

  $i: $i + 1;
}
