/* Menu contextuel */
.menu-contextuel {
  ul {
    padding-left:0;
    li {
      display: none;
      &.trail {
        display: block;
        * {
          display: block;
        }
        a {
          display:block;
          padding:1rem;
          background-color:$brand-primary;
          color:#FFFFFF;
          transition:background-color 0.6s ease;
          &:hover {
            text-decoration: none;
            background-color:$gray-lighter;
            color:$brand-primary;
          }
          &.active {
            background-color:$gray-light;
            color:#FFFFFF;
            &:hover {
              background-color:$gray-lighter;
              color:$brand-primary;
            }
          }
        }
      }
    }
    &.level_1 {
      &>li {
        &>a {
          display:none;
        }
      }
    }
    &.level_3 {
      li {
        a {
          padding-left: 2rem;
        }
      }
    }
  }
}