/* Liste produits */

.liste-produits {
  .layout_full {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .item {
      width: 33.33%;
      padding:1.5rem;
      position: relative;
      cursor:pointer;
      @media (max-width: 767px) {
        width: 50%;
        padding-left:0;
        padding-right:0;
      }
      @media (max-width: 550px) {
        width: 100%;
      }
      .wrap-item {
        height: 100%;
        background-color:#e7e5e5;
        text-align: center;
        padding-bottom: 6rem;
        position: relative;
        transition:all 0.3s ease;
      }
      &:hover {
        .wrap-item {
          background-color: $brand-primary;
          color:#FFFFFF;
        }
        .actions {
          a {
            background-color: #FFFFFF !important;
            color: $text-color !important;
          }
        }
      }
      ul {
        margin: 0;
        padding:0;
        li {
          list-style-type: none;
        }
      }
      .visuel {
        img {
          max-width: 100%;
          height: auto;
        }
      }
      .nom {
        padding:2.5rem;
        padding-bottom:2rem;
        padding-left:1.5rem;
        padding-right:1.5rem;
        font-size: 2.2rem;
      }
      a {
        position: absolute;
        bottom: 3rem;
        left: 50%;
        transform: translateX(-50%);
        pointer-events: none;
      }
    }
  }
}

/* Critères de recherche */
.criteres-recherche-produits {
  background-color: rgba(#e7e5e5,0.3);
  padding:3rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-bottom:1.5rem;
  @media (max-width: 767px) {
    padding: 3rem;
    >.container {
      padding-left:0;
      padding-right:0;
    }
  }
  .mm_tags {
    > label {
      display: none;
    }
    fieldset {
      display: flex;
      flex-wrap: wrap;
      > span {
        width: 33%;
        padding:1rem;
        @media (max-width: 550px) { width:50%; }
        @media (max-width: 400px) { width:100%; }
        input {
          display: inline-block;
          vertical-align: middle;
          margin:0;
        }
        label {
          display: inline-block;
          vertical-align: middle;
          margin:0;
        }
      }
    }
  }
}

/* Fiche produit */
.fiche-produit {
  margin-bottom: 3rem;
  .visuel {
    float: left;
    margin-right:3rem;
    margin-bottom:3rem;
    ul {
      padding: 0;
      li {
        list-style-type: none;
      }
    }
  }
  .nom {
    font-size:3rem;
    margin-bottom:1.5rem;
  }
}
