/* Article */
.mod_article {
  margin-bottom:3rem;
  transition:opacity 1.6s ease;
  opacity:1;
}

/* Liste d'articles */
.mod_articleList {
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style-type:none;
    }
  }
}