/**********/
/* News   */
/**********/

/* Liste de news */
.news_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .item {
    width: 33.333%;
    padding:1.5rem;
    padding-top:0;
    margin-bottom:3rem;
    position: relative;
    @media (max-width: 680px) { width: 50%; }
    @media (max-width: 430px) { width: 100%; }
    .date {
      right:1.5rem;
    }
  }
}

/* Bloc news */
.bloc-news {
  .date {
    position: relative;
    right: auto;
    color:$text-lighter;
    display: flex;
    justify-content: flex-end;
    .date-container {
      background-color: $brand-primary;
      display: flex;
      align-items: center;
      line-height: 1;
      padding: 1.4rem;
      padding-left: 1.7rem;
      padding-right: 1.7rem;
      .jour {
        font-size: 1.6rem;
        line-height: 1;
        font-family: "Poppins SemiBold", sans-serif;
      }
      .icon {
        margin-left: 0.4rem;
        margin-right: 0.4rem;
      }
    }
  }
  .image {
    position:relative;
    overflow: hidden;
    .image_container {
      margin: 0;
      width: 100% !important;
      img {
        max-width: 100%;
        height: auto;
      }
      .caption {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
        background-color: rgba(#FFFFFF,0.80);
        padding: 0.5rem;
      }
    }
    .bt-detail {
      position: absolute;
      left:0;
      bottom:-20rem;
      width: 100%;
      padding: 4.5rem;
      background-color: $brand-primary;
      color: #FFFFFF;
      text-align: center;
      font-size: 1.8rem;
      transition: all 0.3s ease;
      &::after {
        content: "+";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 5.2rem;
        height: 5.2rem;
        transition: all 0.3s ease;
        z-index: 1;
        background-color: #FFF !important;
        color: $brand-primary;
        border-radius: 50%;
        font-size: 3.7rem;
        font-family: "Poppins Light", sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
      }
    }
  }
  .h1 {
    text-align: left;
    font-size: 1.8rem;
    display: block;
    padding-bottom: 0.8rem;
    padding-top: 0.8rem;
    margin:0;
    text-transform: uppercase;
    font-family: "Poppins SemiBold", sans-serif;
    border-bottom: 3px solid $brand-primary;
    &::after {
      display: none;
    }
  }
  .teaser {
    font-size: 1.6rem;
    padding-top: 2.5rem;
  }
  &.clickable {
    cursor: pointer;
    &:hover {
      .image {
        .bt-detail {
          bottom:0;
          height: 100%;
          opacity: 0.8;
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
}

$i: 1;
@while $i < 8 {
  .bloc-news.cat-#{$i} .date-container { background-color: map-get($couleurs, $i); }
  .bloc-news.cat-#{$i} .image .bt-detail { background-color: darken(map-get($couleurs, $i),5%) }
  .bloc-news.cat-#{$i} .image .bt-detail::after { color: map-get($couleurs, $i) }
  .bloc-news.cat-#{$i} .h1 { border-bottom: 3px solid  map-get($couleurs, $i) }

  $i: $i + 1;
}