/* Tableaux */

.table {
  thead {
    &> tr {
      &> th {
        border-bottom: none;
      }
    }
  }
  tfoot {
    &> tr {
      &> td {
        border-bottom: none;
      }
    }
  }
}

.ce_table {
  &.table-bordered {
    &> thead:first-child > tr:first-child > th {
      border-top:1px solid #ddd;
    }
  }
  &.table-striped {
    &> thead:first-child > tr:first-child > th {
      border-top:0;
    }
  }
}