/* Fil d'arianne */
.breadcrumbs {
  padding: 0.5rem;
  background-color: #FFFFFF;
  border-top:1px solid #DDDDDD;
  border-bottom:1px solid #DDDDDD;
  h1 {
    font-size: 26px;
    margin-top: 12px;
  }
  .breadcrumb {
    padding: 0.8rem 1.5rem;
    padding-right: 0;
    padding-left: 0;
    position: relative;
    margin-bottom:0;
    background: none;
    li {
      .active {
        text-decoration: none;
        color:$brand-primary;
      }
      a:hover {
        text-decoration: none;
        color:$brand-primary;
      }
    }
    a {
      color: #777;
    }
    >li + li::before {
      padding: 0 0.5rem;
      color: #ccc;
      content: "/ ";
    }
  }
}