/* Main menu */
.main_menu {
  @media (max-width: 991px) {
    text-align: center;
  }
  ul {
    position:relative;
    padding:0;
    margin:0;
    white-space:nowrap;
    display: flex;
    justify-content: flex-start;
    li {
      position:relative;
      list-style-type:none;
      a {
        font-size: 2rem;
        text-transform: uppercase;
        color: $text-lighter;
        @media (max-width: 991px) {
          font-size: 2.8rem;
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
    &.level_2 {
      position:absolute;
      padding:0;
      padding-top:0;
      top:-200rem;
      opacity:0;
      z-index: 2;
      transition:top 0 ease, opacity 0.6s ease;
      left:0;
      min-width: 100%;
      display: flex;
      flex-direction: column;
      max-width: 45rem;
      >li {
        >a {
          background-color: rgba($brand-primary,0.9);
          color: #FFFFFF !important;
          padding:1rem !important;
          padding-left:2rem !important;
          padding-right:2rem !important;
          transition:all 0.6s ease !important;
          text-align: left !important;
          white-space: normal;
          line-height: 1.2;
        }
        &:hover,
        &.active {
          a { background-color: $text-color !important; }
        }
      }
    }
    &.level_1 {
      >li {
        position:relative;
        width: 24rem;
        margin-left: 1px;
        margin-right: 1px;
        @media (max-width: 1680px) {
          width: 22rem;
        }
        @media (max-width: 1550px) {
          width: 20rem;
        }
        @media (max-width: 1350px) {
          width: 18rem;
        }
        @media (max-width: 1100px) {
          width: 16rem;
        }
        &:hover {
          .level_2 {
            transition:top 0.03s ease, opacity 0.6s ease;
            top:100%;
            opacity:1;
          }
        }
        &:hover {
          a {
            color: $text-lighter;
            &::after {
              width: 100%;
            }
          }
          >a {
            transition: all 0.3s ease;
          }
        }
        &.active, &.trail {
          a {
            color: $text-lighter;
            &::after {
              width: 100%;
            }
          }
          >a {
            transition: all 0.3s ease;
          }
          &:hover {
            a {
              color: $text-lighter;
              &::after {
                width: 100%;
              }
            }
          }
        }
        a {
          display:block;
          padding:2rem;
          padding-left:1rem;
          padding-right:1rem;
          color:$text-lighter;
          transition:color 0.6s ease;
          text-align: center;
          position: relative;
          @media (max-width: 1550px) {
            font-size: 1.7rem;
          }
          @media (max-width: 1350px) {
            font-size: 1.55rem;
          }
          @media (max-width: 1100px) {
            font-size: 1.5rem;
          }
        }
        > a {
          &::after {
            display: none;
            content:"";
            height: 4px;
            background-color: $text-lighter;
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 0;
            transition: all 0.4s ease;
          }
        }
        &.page-accueil {
          display: none;
        }
      }
    }
    &.level_3 {
      display: none;
    }
  }
}

$i: 1;
@while $i < 8 {
  .main_menu ul.level_1 >li.rub#{$i} > a { background-color: map-get($couleurs, $i) }
  .main_menu ul.level_1 >li.rub#{$i} > ul.level_2> li > a { background-color: map-get($couleurs, $i) }
  .main_menu ul.level_1 >li.rub#{$i} > ul.level_2> li > a:hover,
  .main_menu ul.level_1 >li.rub#{$i} > ul.level_2> li > a.active {
    background-color: darken(map-get($couleurs, $i),10%) !important;
  }
  .main_menu ul.level_1 >li.rub#{$i}:hover >a {background-color: darken(map-get($couleurs, $i),5%) !important;}
  .main_menu ul.level_1 >li.rub#{$i}.active >a {background-color: darken(map-get($couleurs, $i),5%) !important;}
  .main_menu ul.level_1 >li.rub#{$i}.trail >a {background-color: darken(map-get($couleurs, $i),5%) !important;}
  $i: $i + 1;
}

/* Sticky */
.header {
  background-color: #FFFFFF;
  position: relative;
  &.isStuck {
    .main_menu {
      ul {
        &.level_1 {
          >li {
            a {
              padding-top: 1.5rem;
              padding-bottom: 1.5rem;
            }
          }
        }
      }
    }
  }
}