/*************/
/* Home page */
/*************/

.page-accueil {
  section {
    padding:5rem;
    z-index: 1;
    position: relative;
    @media (max-width: 767px) {
      padding: 3rem;
      >.container {
        padding-left:0;
        padding-right:0;
      }
    }
    &.zone-bas {
      padding-bottom: 0;
      padding-bottom: 1.5rem;
      background-color: #FFF;
      .block {
        margin-bottom: 0;
      }
    }
  }
  .content-wrapper {
    padding-top:0;
    padding-bottom:0;
    @media (max-width: 767px) {
      padding:0;
    }
  }
  .body-wrapper {
    &::before {
      top: 108rem;
      width: 130rem;
      z-index: 1;
    }
  }
  footer {
    background-color: #FFF;
  }
  .panopocket {
    position: absolute;
    right: 20rem;
    top: 25rem;
    height: 55rem;
    z-index: 2;
    transform: rotateZ(4deg);
    background-color: #FFF;
    -webkit-box-shadow: 5px 5px 17px 2px rgba(0,0,0,0.82);
    box-shadow: 5px 5px 17px 2px rgba(0,0,0,0.82);
    border-radius: 0.8rem;
    @media (max-width: 1530px) {
      top: 20rem;
      height: 50rem;
      iframe {
        width: 250px;
      }
    }
    @media (max-width: 1530px) {
      height: 45rem;
    }
    @media (max-width: 1240px) {
      top: 15rem;
    }
    @media (max-width: 1070px) {
      height: 40rem;
      iframe {
        width: 200px;
      }
    }
    @media (max-width: 991px) {
      height: 45rem;
      right: 15rem;
      display: none;
    }
  }

  /* Zone droite */
  .zone-droite {
    padding-left: 5rem;
    @media (max-width: 767px) {
      padding-left: 1.5rem;
    }
  }

  /* Texte de présentation */
  .texte-presentation {
    padding-bottom: 3rem;
    border-bottom: 1px solid rgba(#707070,0.42);
    a {
      display: inline-flex;
      position: relative;
      align-items: center;
      justify-content: flex-end;
      transition: all 0.3s ease;
      color: $text-color;
      &::before {
        content:"+";
        width: 3.3rem;
        height: 3.3rem;
        background-color: $brand-primary;
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 0.5rem;
      }
      &:hover {
        transform: translateY(-0.5rem);
        text-decoration: none;
      }
    }
  }

  /* Actualités */
  .zone-actualites {
    .mod_newslist {
      >h1 {
        font-family: 'Outfit Regular', sans-serif;
        font-size: 3.6rem;
        margin:0;
        margin-bottom:5rem;
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        @media (max-width: 767px) { margin-top: 1.5rem; }
      }
      .toutes-les-actualites {
        text-align: center;
        margin-top:3rem;
        > a {
          display: inline-block;
          background-color: transparent !important;
          border:1px solid $text-color;
          color: $text-color !important;
          &:hover {
            border:1px solid $brand-primary;
            color: $brand-primary !important;
          }
        }
      }
    }
  }

  /* Evenèments */
  .zone-evenements {
    .mod_eventlist {
      >h2 {
        @extend h1;
        text-transform: uppercase;
        position: relative;
        padding-bottom: 1rem;
        display: block;
        &::after {
          content: "";
          width: 4rem;
          height: .3rem;
          position: absolute;
          left: 0;
          bottom: 0;
          background-color: $brand-primary;
        }
      }
      .tous-les-evenements {
        text-align: center;
        margin-top:3rem;
        position: absolute;
        left: 0;
        bottom: 0;
        > a {
          display: inline-block;
          background-color: $brand-primary !important;
          color: #FFF !important;
          border-radius: 5rem;
          font-size: 1.4rem;
          padding: 1.2rem;
          padding-left: 1.5rem;
          padding-right: 1.5rem;
          transition: all 0.3s ease;
          &:hover {
            border:0;
            text-decoration: none;
            transform: translateX(0.5rem);
          }
        }
      }
      .swiper.mode-carousel {
        padding-top: 0;
        padding-bottom: 5rem;
        .swiper-buttons {
          bottom:1.5rem;
          width: 7rem;
          top: auto;
          .swiper-button {
            width: 3.3rem;
            height: 3.3rem;
            border-radius: 50%;
            border:1px solid $brand-primary;
            background-color:$brand-primary;
            opacity: 1;
            &.swiper-button-disabled {
              background-color: transparent;
              color: $brand-primary;
              opacity: 1 !important;
            }
          }
        }
        .bloc-news {
          margin-bottom: 0;
        }
      }
    }
  }

  /* Diaporama */
  .zone-diaporama {
    padding: 0 !important;
    position: relative;
    &::after {
      position: absolute;
      content:"";
      width: 100%;
      height: 100%;
      left: 0;
      top: 25.5%;
      background-image: url("/templates/theme-site/img/theme/fond-diaporama-accueil-full.png");
      background-repeat: no-repeat;
      background-position: bottom left;
      background-size: contain;
      z-index: 1;
    }
    .mod_article {
      margin-bottom: 0;
    }
    .swiper-button {
      background-image:none !important;
      width: 3.3rem;
      height: 3.3rem;
      top: 55%;
      border-radius: 50%;
      border: 1px solid transparent;
      background-color: rgba(#FFF,0.5);
      opacity: 0.8;
      text-align:center;
      transition:all 0.3s ease;
      @media (max-width: 620px) {
        top: auto !important;
        bottom:25%;
        &.swiper-button-prev {
          left: auto !important;
          right: 5rem !important;
        }
      }
      @media (max-width: 380px) {
        width: 2.8rem;
        height: 2.8rem;
        &.swiper-button-prev {
          right: 4.65rem !important;
        }
      }
      &::after {
        font-family:"kfont";
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        text-decoration: inherit;
        font-size: 1.2rem;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &.swiper-button-prev::after { content:"\EA07"; }
      &.swiper-button-next::after { content:"\EA08"; }
      &:hover {
        opacity : 1;
        top: calc(55% - 0.5rem);
      }

    }
    .swiper-button-disabled {
      display:none;
    }
    .slider-title {
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      z-index: 2;
      transform: translateY(-50%);
      color:#FFF;
      @media (max-width: 767px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
      @media (max-width: 480px) {
        transform: translateY(calc(-50% + 3rem));
      }
      .subtitle {
        font-size: 3.7rem;
        @media (max-width: 767px) {
          font-size: 2.7rem;
        }
        @media (max-width: 480px) {
          line-height: 1;
          margin-bottom: 0.5rem;
          font-size: 2.2rem;
        }
        @media (max-width: 380px) {
          font-size: 2rem;
        }
      }
      .title {
        font-size: 5.9rem;
        text-transform: uppercase;
        font-family: "Poppins Semibold", sans-serif;
        @media (max-width: 767px) {
          font-size: 4.9rem;
        }
        @media (max-width: 480px) {
          line-height: 1;
          font-size: 4.5rem;
        }
        @media (max-width: 380px) {
          font-size: 4.2rem;
        }
      }
    }
    .swiper {
      .swiper-container {
        &::before {
          content:"";
          height: 49.3rem;
          height: 55%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 2;
          /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#55a4c8+0,55a4c8+100&1+0,0+100 */
          background: -moz-linear-gradient(top,  rgba(85,164,200,1) 0%, rgba(85,164,200,0) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top,  rgba(85,164,200,1) 0%,rgba(85,164,200,0) 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom,  rgba(85,164,200,1) 0%,rgba(85,164,200,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#55a4c8', endColorstr='#0055a4c8',GradientType=0 ); /* IE6-9 */
        }
        &::after {
          content:"";
          height: 47.4rem;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          width: calc(100% + 2px);
          z-index: 2;
          background-image: url("/templates/theme-site/img/theme/vague-diapo-accueil.svg");
          background-repeat: no-repeat;
          background-position: center bottom;
        }
        &.no-scroll {
          .swiper-button {
            display: none;
          }
        }
        .swiper-wrapper {
        }
      }
      .swiper-slide {
        img {
          max-width: 100%;
          height: auto;
        }
        .texte {
          text-align: right;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          top:50%;
          transform: translateY(-50%);
          color: #FFFFFF;
          z-index: 3;
          display: none;
          >.container {
            padding-right:3rem;
            padding-left:3rem;
          }
          @media (max-width: 1400px) { top:40%; }
          @media (max-width: 991px) { top:3rem; transform:none; }
          @media (max-width: 767px) {
            position: relative;
            top:auto;
            left:auto;
            width: auto;
            background-color: $text-color;
            text-align: center;
            padding:1.5rem;
          }
          .titre {
            margin:0;
            font-weight: 600;
            text-transform: uppercase;
            font-family: 'Outfit Regular', sans-serif;
            font-size: 3.6rem;
          }
          .sous-titre {
            margin:0;
            margin-top:1rem;
            font-weight: 300;
            text-transform: uppercase;
            font-family: 'Outfit Regular', sans-serif;
            font-size: 3.2rem;
          }
          .url {
            margin-top:3.6rem;
            background-color: #372c26 !important;
            @media (max-width: 1199px) {
              margin-top:1rem;
            }
            @media (max-width: 767px) {
              background-color: $brand-primary !important;
            }
            &:hover {
              background-color: $brand-primary !important;
            }
          }
        }
      }
    }
  }

  /* Raccourcis */

  .zone-raccourcis {
    >.container {
      @media (max-width: 767px) {
        max-width: 100%;
      }
    }
  }

  .raccourcis-accueil {
    @media (max-width: 767px) {
      margin-bottom: 0;
    }
    .layout_full {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      @media (max-width: 380px) {
        flex-direction: column;
        flex-wrap: wrap;
      }
      .item {
        width: calc(100% / 6);
        display: flex;
        flex-direction: column;
        align-items: center;
        @include transition;
        &.clickable {
          cursor: pointer;
          &:hover {
            @include push-top;
          }
        }
        @media (max-width: 600px) {
          width: calc(100% / 5);
        }
        @media (max-width: 520px) {
          width: calc(100% / 4);
        }
        @media (max-width: 380px) {
          width: 100%;
          margin-bottom: 3rem;
        }
        .picto {
          background-color: $brand-primary;
          border-radius: 50%;
          width: 14.5rem;
          height: 14.5rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          @media (max-width: 1199px) {
            width: 14.5rem;
            height: 14.5rem;
          }
          @media (max-width: 720px) {
            width: 12rem;
            height: 12rem;
          }
          @media (max-width: 420px) {
            width: 10rem;
            height: 10rem;
          }
          @media (max-width: 380px) {
            width: 14.5rem;
            height: 14.5rem;
          }
          >.value {
            @media (max-width: 1199px) {
              width: 6rem;
            }
            @media (max-width: 420px) {
              width: 5.5rem;
            }
            @media (max-width: 380px) {
              width: 8rem;
            }
          }
          .value {
            margin-bottom: 0.5rem;
            ul {
              padding: 0;
              margin: 0;
              li {
                list-style-type: none;
                img {
                  max-width: 100%;
                  height: auto;
                }
              }
            }
          }
          .libelle {
            font-size: 1.4rem;
            text-transform: uppercase;
            color:#FFFFFF;
            padding-left: 3.5rem;
            padding-right: 3.5rem;
            text-align: center;
            line-height: 1;
            @media (max-width: 420px) {
              font-size: 1.2rem;
            }
            @media (max-width: 380px) {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }

  /* Parallax */
  .zone-parallax {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

}

/* Bulletin municipal */
.bulletin-municipal {
  margin-top: 8rem;
  h2 {
    @extend h1;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 1rem;
    display: block;

    &::after {
      content: "";
      width: 4rem;
      height: .3rem;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: $brand-primary;
    }
  }
  .layout_full {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    @media (max-width: 441px) {
      flex-direction: column;
    }
    &::before {
      content: "";
      width: 13rem;
      height: 13rem;
      border-radius: 50%;
      background-color: $brand-primary;
      background-image: url("/templates/theme-site/img/theme/picto-bulletin.png");
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 2.5rem;
    }
    .item {
      display: flex;
      align-items: center;
      .libelle {
        font-size: 2.2rem;
        text-transform: uppercase;
        margin-right: 1rem;
      }
      .fichier {
        transition: all 0.3s ease;
        .size {
          font-family: "Poppins Light", sans-serif;
          color: $text-color;
          font-size: 2.2rem;
          text-transform: none;
          position: relative;
          &::before {
            content:"-";
          }
        }
        a {
          &:hover {
              text-decoration: none;
          }
        }
      }
    }
    &:hover {
      transform: translateX(0.5rem);
      color: $brand-primary;
      .fichier {
        a {
          text-decoration: none;
          color: $brand-primary !important;
        }
      }
    }
  }
}

/* Parallax */
.parallax-window {
  min-height: 500px;
  background: transparent;
  @media (max-width: 1500px) {
    min-height: 400px;
  }
  @media (max-width: 1199px) {
    min-height: 300px;
  }
  @media (max-width: 767px) {
    min-height: 250px;
  }
  @media (max-width: 380px) {
    min-height: 200px;
  }
}