html {
  font-family: 'Poppins Regular', sans-serif;
  font-size: 62.5%;
  overflow-x: hidden;
  @media (max-width: 1800px) { font-size: 55%; }
  @media (max-width: 1200px) { font-size: 50%; }
  @media (max-width: 990px) { font-size: 45%; }
}

body {
  background-color: #FFFFFF;
  font-size: 1.8rem;
  overflow-x: hidden;
}

.body-wrapper {
  max-width: 1920px;
  margin: 0 auto;
}

.content-wrapper {
  padding:0;
  padding-top:3rem;
  padding-bottom:3rem;
  background-color: #FFFFFF;
  @media (max-width: 767px) {
    padding:3rem;
  }
  .block {
    margin-bottom: 3rem;
  }
}

#topcontrol {
  z-index: 2;
}

.pseudoStickyBlock {
  display: none !important;
}

body,
button,
fieldset,
form,
html,
textarea {
  font-family: 'Poppins Regular', sans-serif;
}

.overflow-hidden { overflow:hidden; }
.invisible { display:none !important; }
.inline-block { display:inline-block; }
.float_left { float:left; }
.float_right { float:right; }
.gauche { float:left; display:inline-block; vertical-align:top; }
.droite { float:right; }
.centre { text-align:center; }
.ce_image.centre figure, .ce_text.centre figure { margin:0 auto; }
.est-visible { opacity : 1 !important; }
.majuscule { text-transform: uppercase; }

a {
  color:$brand-primary;
  &:focus,
  &:hover {
    color:darken($brand-primary,10%);
  }
  &:hover {
    text-decoration: underline;
  }
}

$i: 1;
@while $i < 8 {
  body.rub#{$i} a { color: map-get($couleurs, $i) }
  body.rub#{$i} a:focus { color: darken(map-get($couleurs, $i),10%) }
  body.rub#{$i} a:hover { color: darken(map-get($couleurs, $i),10%) }
  $i: $i + 1;
}


/* Marges */

.marge-2x { padding:30px; }

.marge-bas { padding-bottom:10px; }
.marge-bas-2x { padding-bottom:30px; }

.marge-haut { padding-top:10px; }
.marge-haut-2x { padding-top:30px; }

.marge-gauche { padding-left:10px; }
.marge-gauche-2x { padding-left:30px; }

.marge-droite { padding-right:10px; }
.marge-droite-2x { padding-right:30px; }

.marges-auto { margin:0 auto; }


/* Bordures */

.bordure { border: 1px solid #DDDDDD; }
.bordure-2x { border: 2px solid #DDDDDD; }
.bordure-p { border: 1px dotted #DDDDDD; }
.bordure-t { border: 1px dashed #DDDDDD; }

.bordure-couleur { border: 1px solid $brand-primary; }
.bordure-couleur-2x { border: 2px solid $brand-primary; }
.bordure-couleur-p { border: 1px dotted $brand-primary; }
.bordure-couleur-t { border: 1px dashed $brand-primary; }

.bordure-bas { border-bottom: 1px solid #DDDDDD; }
.bordure-bas-2x { border-bottom: 2px solid #DDDDDD; }
.bordure-bas-p { border-bottom: 1px dotted #DDDDDD; }
.bordure-bas-t { border-bottom: 1px dashed #DDDDDD; }

.bordure-bas-couleur { border-bottom: 1px solid $brand-primary; }
.bordure-bas-couleur-2x { border-bottom: 2px solid $brand-primary; }
.bordure-bas-couleur-p { border-bottom: 1px dotted $brand-primary; }
.bordure-bas-couleur-t { border-bottom: 1px dashed $brand-primary; }

.bordure-haut { border-top: 1px solid #DDDDDD; }
.bordure-haut-2x { border-top: 2px solid #DDDDDD; }
.bordure-haut-p { border-top: 1px dotted #DDDDDD; }
.bordure-haut-t { border-top: 1px dashed #DDDDDD; }

.bordure-haut-couleur { border-top: 1px solid $brand-primary; }
.bordure-haut-couleur-2x { border-top: 2px solid $brand-primary; }
.bordure-haut-couleur-p { border-top: 1px dotted $brand-primary; }
.bordure-haut-couleur-t { border-top: 1px dashed $brand-primary; }

.bordure-droite { border-right: 1px solid #DDDDDD; }
.bordure-droite-2x { border-right: 2px solid #DDDDDD; }

.bordure-droite-couleur { border-right: 1px solid $brand-primary; }
.bordure-droite-couleur-2x { border-right: 2px solid $brand-primary; }

.bordure-gauche { border-left: 1px solid #DDDDDD; }
.bordure-gauche-2x { border-left: 2px solid #DDDDDD; }

.bordure-gauche-couleur { border-left: 1px solid $brand-primary; }
.bordure-gauche-couleur-2x { border-left: 2px solid $brand-primary; }


/* Couleurs de fond */

.fond { background-color: #eef6f9; }
.fond-couleur { background-color: $brand-primary; color:#FFFFFF; }
.fond-couleur-alternative { background-color: $brand-primary-alt; }
.fond-blanc { background-color: #FFFFFF; }
.fond-noir { background-color: #413E41; color:#FFFFFF; }

.fond-couleur a,
.fond-couleur a:hover,
.fond-couleur p,
.fond-couleur li,
.fond-couleur .caption {
  color:#FFFFFF;
}

/* Couleurs */

.couleur { color: $brand-primary; }
.couleur-alternative { color: $brand-primary-alt; }
.gris { color: #e4e2df; }
.noir { color: #372c26; }
.blanc { color: #FFFFFF; }
.color { color: $brand-primary; }
.rouge { color: #B8282E; }

$i: 1;
@while $i < 8 {
  body.rub#{$i} .color { color: map-get($couleurs, $i) }
  body.rub#{$i} .couleur { color: map-get($couleurs, $i) }
  body.rub#{$i} .fond-couleur { background-color: map-get($couleurs, $i); }

  body.rub#{$i} .bordure-couleur { border: 1px solid map-get($couleurs, $i); }
  body.rub#{$i} .bordure-couleur-2x { border: 2px solid map-get($couleurs, $i); }
  body.rub#{$i} .bordure-couleur-p { border: 1px dotted map-get($couleurs, $i); }
  body.rub#{$i} .bordure-couleur-t { border: 1px dashed map-get($couleurs, $i); }

  body.rub#{$i} .bordure-bas-couleur { border-bottom: 1px solid map-get($couleurs, $i); }
  body.rub#{$i} .bordure-bas-couleur-2x { border-bottom: 2px solid map-get($couleurs, $i); }
  body.rub#{$i} .bordure-bas-couleur-p { border-bottom: 1px dotted map-get($couleurs, $i); }
  body.rub#{$i} .bordure-bas-couleur-t { border-bottom: 1px dashed map-get($couleurs, $i); }

  body.rub#{$i} .bordure-haut-couleur { border-top: 1px solid map-get($couleurs, $i); }
  body.rub#{$i} .bordure-haut-couleur-2x { border-top: 2px solid map-get($couleurs, $i); }
  body.rub#{$i} .bordure-haut-couleur-p { border-top: 1px dotted map-get($couleurs, $i); }
  body.rub#{$i} .bordure-haut-couleur-t { border-top: 1px dashed map-get($couleurs, $i); }

  body.rub#{$i} .bordure-droite-couleur { border-right: 1px solid map-get($couleurs, $i); }
  body.rub#{$i} .bordure-droite-couleur-2x { border-right: 2px solid map-get($couleurs, $i); }

  body.rub#{$i}.bordure-gauche-couleur { border-left: 1px solid map-get($couleurs, $i); }
  body.rub#{$i}.bordure-gauche-couleur-2x { border-left: 2px solid map-get($couleurs, $i); }

  $i: $i + 1;
}

body.rub1 .couleur-alternative { color: lighten(map-get($couleurs, 1),50%); }
body.rub1 .fond-couleur-alternative { background-color: lighten(map-get($couleurs, 1),50%); }

body.rub2 .couleur-alternative { color: lighten(map-get($couleurs, 2),50%); }
body.rub2 .fond-couleur-alternative { background-color: lighten(map-get($couleurs, 2),50%); }

body.rub3 .couleur-alternative { color: lighten(map-get($couleurs, 3),40%); }
body.rub3 .fond-couleur-alternative { background-color: lighten(map-get($couleurs, 3),40%); }

body.rub4 .couleur-alternative { color: lighten(map-get($couleurs, 4),40%); }
body.rub4 .fond-couleur-alternative { background-color: lighten(map-get($couleurs, 4),40%); }

body.rub5 .couleur-alternative { color: lighten(map-get($couleurs, 5),50%); }
body.rub5 .fond-couleur-alternative { background-color: lighten(map-get($couleurs, 5),50%); }

body.rub6 .couleur-alternative { color: lighten(map-get($couleurs, 6),40%); }
body.rub6 .fond-couleur-alternative { background-color: lighten(map-get($couleurs, 6),40%); }

body.rub7 .couleur-alternative { color: lighten(map-get($couleurs, 7),35%); }
body.rub7 .fond-couleur-alternative { background-color: lighten(map-get($couleurs, 7),35%); }