/* Swiper - Mode par défaut
------------------------------------*/
.swiper {
  position:relative;
  max-width:100%;
  .swiper-pagination {
    display:none;
  }
  .swiper-slide {
    figure {
      max-width:100%;
      margin-bottom: 0;
      img {
        transition: all 0.8s ease-in-out 0s;
      }
      a:hover {
        img {
          opacity: 0.8;
          transform: scale(1.02);
        }
        .swiper-icon {
          visibility:visible;
        }
      }
    }
    .swiper-icon {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 90;
      visibility:hidden;
      &::after {
        position:absolute;
        width:5rem;
        height:5rem;
        font-family:"kfont";
        font-size:2.4rem;
        left:50%;
        top:50%;
        margin-left:-2.5rem;
        margin-top:-2.5rem;
        color:#FFFFFF;
        text-align:center;
        padding-top:0.5rem;
      }
      &.zoom {
        &::after {
          content:"\EA24";
        }
      }
      &.url {
        &::after {
          content:"\EA08";
        }
      }
    }
  }
  .swiper-button {
    background-image:none !important;
    background-color:#000000;
    color:#FFFFFF;
    text-align:center;
    opacity : 0.3;
    transition:all 0.6s ease;
    &::after {
      font-family:"kfont";
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      text-decoration: inherit;
      font-size: 2rem;
    }
    &.swiper-button-prev::after { content:"\EA07"; }
    &.swiper-button-next::after { content:"\EA08"; }
    &:hover {
      opacity : 0.8;
    }

  }
  .swiper-button-disabled {
    display:none;
  }
  .swiper-caption {
    font-size:1.2rem;
  }
}

/* Swiper - Mode diaporama
------------------------------------*/
.swiper {
  &.mode-diaporama {
    .swiper-button-prev { left: 0 !important; }
    .swiper-button-next { right:0 !important; }
    .swiper-button {
      margin-top:0;
      transform: translateY(-50%);
      &::after {
        position:absolute;
        width:100%;
        left:50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }
}

/* Swiper - Mode carousel
------------------------------------*/
.swiper {
  &.mode-carousel {
    padding-top: 5rem;
    .swiper-buttons {
      height: 2rem;
      position: absolute;
      right: 0;
      top: 1.5rem;
      width: 4.3rem;
      z-index: 2;
      .swiper-button {
        height:2rem;
        width:2rem;
        margin-top:0 !important;
        top:0 !important;
        &.swiper-button-prev {
          left:0 !important;
        }
        &.swiper-button-next {
          right:0 !important;
        }
        &::after {
          font-size:1.2rem;
          position:absolute;
          left:0;
          right:0;
          top:50%;
          transform:translateY(-50%);
        }
      }
      .swiper-button-disabled {
        opacity : 0.1 !important;
        display:block;
      }
    }
  }
}

.carousel {
  max-width:100%;
}