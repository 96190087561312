/****************/
/* Evenements   */
/****************/

/* Liste d'évènements */
.event_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .item {
    width: 33.333%;
    padding:1.5rem;
    padding-top:0;
    margin-bottom:3rem;
    position: relative;
    @media (max-width: 680px) { width: 50%; }
    @media (max-width: 430px) { width: 100%; }
  }
}