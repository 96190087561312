/**********/
/* Header */
/**********/
.header {
  position: absolute;
  width: 100%;
  z-index: 4;
  background-color: transparent !important;
  /*
  @media (max-width: 991px) {
    position: relative !important;
    background-color: $brand-primary !important;
  }
  */

  &.isStuck {
    width:100%;
    left: 0;
    z-index: 4;
    background-color: rgba(#eef6f9,0.85) !important;
    box-shadow: 0px 10px 5px 0px rgba(#656565,0.3);
    @media (max-width: 991px) {
      position: relative !important;
      top:auto !important;
      left:auto !important;
      box-shadow: none !important;
      background-color: transparent !important;
      .header_main {
        .logo {
          display: none !important;
        }
      }
    }
    .header_main {
      padding-left: .4rem;
      .logo {
        width: 6.1rem;
        border: 1px solid rgba(#000,0.1);
      }
      .mobile_menu {
        .bouton_menu_mobile {
          position: fixed;
        }
      }
    }
    .header_top {
      color: $text-color;
      a {
        color: $text-color;
      }
      .horaires {
        &::before {
          border:1px solid $text-color;
        }
      }
      .social_block {
        .item {
          .field {
            a {
              border:1px solid $text-color;
              color: $text-color;
              &:hover {
                background-color: $text-color;
                color: #FFF !important;
              }
            }
          }
        }
      }
      .contact {
        a {
          &::after {
            border:1px solid $text-color;
          }
          &:hover {
            color: $text-color !important;
          }
        }
      }
      .ce_form {
        .formulaire-de-recherche {
          input {
            color: $text-color;
            border: 1px solid $text-color;
            background-color: #FFF;
            &::placeholder {
              color: $text-color;
            }
          }
          button {
            border:1px solid $text-color;
          }
        }
      }
    }
  }

  /* Header top */
  .header_top {
    background-color:transparent;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    right:0;
    top:0;
    padding:0.5rem;
    padding-left:4rem;
    padding-right:4rem;
    width: 100%;
    text-align: right;
    font-size: 1.2rem;
    color: #FFFFFF;
    @media (max-width: 991px) {
      padding-top: 1rem;
      padding-left: 18rem;
      padding-right: 1rem;
    }
    a {
      color: #FFFFFF;
      margin-left:1.5rem;
      transition:all 0.3s ease;
      &:hover {
        color: $brand-primary !important;
        text-decoration: none;
      }
    }
    .horaires {
      display: flex;
      align-items: center;
      position: relative;
      font-size: 1.6rem;
      @media (max-width: 991px) {
        text-align: left;
      }
      @media (max-width: 680px) {
        display: none;
      }
      &::before {
        content:"\EA39";
        font-family: "kfont";
        width: 2.6rem;
        height: 2.6rem;
        border:1px solid #FFF;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.5rem;
        @media (max-width: 991px) {
          width: 4.6rem;
          height: 4.6rem;
          font-size: 2.5rem;
        }
      }
      p {
        margin-bottom: 0;
      }
      br {
        display: none;
        @media (max-width: 991px) {
          display: block;
        }
      }
    }
    .social_block {
      margin-left: 3.5rem;
      @media (max-width: 991px) {
        margin-left: 1.5rem;
      }
      @media (max-width: 680px) {
        margin-left: 0;
      }
      @media (max-width: 420px) {
        display: none;
      }
      .item {
        display: flex;
        align-items: center;
        position: relative;
        font-size: 1.6rem;
        .field {
          a {
            width: 2.6rem;
            height: 2.6rem;
            border:1px solid #FFF;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0.2rem;
            margin-left: 0.2rem;
            background-color: transparent;
            color: #FFF;
            transition: all 0.3s ease;
            @media (max-width: 991px) {
              width: 4.6rem;
              height: 4.6rem;
            }
            &::before {
              font-size: 1.6rem;
              @media (max-width: 991px) {
                font-size: 2.5rem;
              }
            }
            &:hover {
              background-color: #FFF;
              color: $brand-primary;
            }
          }
        }
      }
    }
    .contact {
      margin-left: 3.5rem;
      @media (max-width: 991px) {
        margin-left: 1rem;
      }
      a {
        display: flex;
        align-items: center;
        position: relative;
        font-size: 1.6rem;
        margin-left: 0;
        transition: all 0.3s ease;
        @media (max-width: 991px) {
          font-size: 2.5rem;
        }
        &::after {
          content:"\EA40";
          font-family: "kfont";
          width: 2.6rem;
          height: 2.6rem;
          border:1px solid #FFF;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 0.5rem;
          background-color: #FFF;
          color: $text-color;
          @media (max-width: 991px) {
            width: 4.6rem;
            height: 4.6rem;
          }
        }
        &:hover {
          color: #FFF !important;
          transform: translateX(-0.5rem);
        }
      }
    }
    .ce_form {
      margin-left: 3.5rem;
      @media (max-width: 991px) {
        margin-left: 1rem;
      }
      .formulaire-de-recherche {
        display: flex;
        align-items: center;
        input {
          padding: 0.5rem;
          padding-right: 0.7rem;
          padding-left: 0.7rem;
          width: 12rem;
          font-size: 1.3rem;
          height: 2.5rem;
          border: 0;
          background: transparent;
          color: #FFF;
          text-align: right;
          transition: all 0.3s ease;
          border: 1px solid #FFF;
          border-radius: 2rem;
          margin-right: 0.5rem;
          @media (max-width: 400px) {
            width: 10rem;
          }
          @media (max-width: 420px) {
            width: 20rem;
          }
          @media (max-width: 360px) {
            width: 15rem;
          }
          &::placeholder {
            color: #FFF;
            font-size: 1.5rem;
          }
          &:focus {
            width: 20rem;
            box-shadow: none;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
            -o-box-shadow: none;
          }
        }
        button {
          font-size: 1.1rem;
          border-radius: 50%;
          color: $text-color;
          width: 2.6rem;
          height: 2.6rem;
          border:1px solid #FFF;
          background-color: #FFF;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 991px) {
            width: 4.6rem;
            height: 4.6rem;
          }
          .icon {
            font-size: 1.5rem;
            line-height: 1.5rem;
            @media (max-width: 991px) {
              font-size: 2.5rem;
            }
          }
        }
      }
    }
  }

  /* Header main */
  .header_main {
    padding: 4rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
    padding-right: 0.4rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease;
    @media (max-width: 1400px) {
      padding-left: 2rem;
    }
    @media (max-width: 991px) {
      position: static;
    }
    .logo {
      z-index: 3;
      width: 13.6rem;
      transition: all 0.3s ease;
      @media (max-width: 1430px) {
        width: 10rem;
      }
      @media (max-width: 1400px) {
        width: 7rem;
      }
      @media (max-width: 1350px) {
        width: 6.3rem;
      }
      @media (max-width: 991px) {
        position: absolute;
        left: 9.6rem;
        top: 1.5rem;
        width: 6.7rem;
      }
    }
    .telephone {
      position: absolute;
      right:4rem;
      top:50%;
      transform:translateY(-50%);
      color: $brand-primary;
      a {
        color: $brand-primary;
        display: flex;
        align-items: center;
        &:hover {
          text-decoration: none;
        }
      }
      .icon {
        vertical-align: middle;
        font-size: 2.6rem;
        line-height: 2.6rem;
        margin-right:1rem;
      }
      span {
        vertical-align: middle;
        font-size: 1.8rem;
        font-family: 'Outfit Regular', sans-serif;
      }
    }
  }

  /* Header menu */
  .header_menu {
    position:relative;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    .menu {
      >.container {
        @media (max-width: 1520px) { padding-right:10rem; }
        @media (max-width: 1330px) { padding-right:15rem; }
        @media (max-width: 1230px) { padding-right:20rem; }
        @media (max-width: 1200px) { padding-right:10rem; }
        @media (max-width: 1110px) { padding-right:15rem; }
        @media (max-width: 1020px) { padding-right:20rem; }
        @media (max-width: 991px) { padding-right:15px; }
      }
    }
  }
}

$i: 1;
@while $i < 8 {
  //body.rub#{$i} .header.isStuck { background-color: rgba(map-get($couleurs, $i),0.75) !important; };
  $i: $i + 1;
}

