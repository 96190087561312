/* Sticky right */
.pin-wrapper {
  position:absolute;
  right:0;
  z-index:9;
  opacity:0.8;
  transition:opacity 0.3s ease;
  &:hover {
    opacity:1;
  }
  .reseaux-sociaux {
    width:5rem;
    text-align: center;
    padding:1rem;
    background-color: #FFFFFF;
    a {
      display: block;
      .icon {
        font-size: 3rem;
        line-height: 3rem;
      }
    }
  }
}

.stick {
  position: fixed;
  right: 0;
  top: 35%;
  transform:translateY(-50%);
  z-index: 4;

  .block {
    margin-bottom: 0;
  }

  @media (max-width: 1240px) {
    top: 35%;
  }

  @media (max-width: 991px) {
    top: 35%;
  }

  @media (max-width: 767px) {
    display: none;
  }

  &.open {
    width: auto !important;
    right: -1px;
    top: 0;
    transform: none;
    .pictos-clic {
      display: flex;
      opacity: 1;
      right: 0;
    }
    .bloc-clic-action {
      display: none;
    }
    .bouton-fermer {
      display: flex;
    }
  }
  .bouton-fermer {
    background-color:#403f3d;
    color: #FFFFFF;
    text-align: center;
    font-size: 2rem;
    cursor: pointer;
    //display:none;
    position: absolute;
    left:-1.7rem;
    width: 1.7rem;
    height: 6.7rem;
    top:50%;
    transform: translateY(-50%);
    align-items: center;
    justify-content: center;
  }
}

.pictos-clic {
  width:13rem;
  position: relative;
  //display: none;
  opacity: 0;
  flex-wrap: wrap;
  cursor: pointer;
  background-color: #dfdfdf;
  -moz-box-shadow: -10px 10px 20px 0px rgba(0,0,0,0.2);
  -webkit-box-shadow: -10px 10px 20px 0px rgba(0,0,0,0.2);
  -o-box-shadow: -10px 10px 20px 0px rgba(0,0,0,0.2);
  box-shadow: -10px 10px 20px 0px rgba(0,0,0,0.2);
  transition: all 0.3s ease;
  position: absolute;
  right: auto;
}

.bloc-clic {
  position: relative;
  padding: 1rem;
  width: 13rem;
  height: 13rem;
  text-align: center;
  transition: all 0.3s ease;
  background-color: #dfdfdf;
  border-right:0;
  border-bottom:0;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  .wrap-item {
    transition: all 0.3s ease;
  }
  figure {
    list-style-type: none;
    text-align: center;
    margin: 0 auto;
    width: 5.7rem;
    height: 5.7rem;
    position: relative;
    .masque {
      position:absolute;
      left:0;
      top: 0;
      width: 5.7rem;
      height: 5.7rem;
      background-color: transparent;
      border-radius: 50%;
    }
    img {
      margin: 0 auto;
      position: relative;
      width: 5.7rem;
      height: 5.7rem;
      transform: translateY(0.3rem);
    }
  }
  &:hover {
    .wrap-item {
      transform: translateY(-0.5rem);
    }
  }

  .libelle {
    font-size: 1.2rem;
    font-family: "Poppins SemiBold", sans-serif;
    line-height: 1.2;
    color: #FFF;
    margin-top:10px;
    font-weight: 500;
    text-transform: uppercase;
    display: block;

    @media (max-width: 1800px) {
      margin-top:0.5rem;
    }

  }
  .lien {
    display: none;
  }
  &:hover {
    background-color: #d5d5d5;
  }
}

.bloc-clic-action {
  border:0;
  cursor: pointer;
  background-color:$brand-primary;
  color: #FFFFFF;
  -moz-box-shadow: -10px 10px 20px 0px rgba(0,0,0,0.2);
  -webkit-box-shadow: -10px 10px 20px 0px rgba(0,0,0,0.2);
  -o-box-shadow: -10px 10px 20px 0px rgba(0,0,0,0.2);
  box-shadow: -10px 10px 20px 0px rgba(0,0,0,0.2);
  width: 9rem;
  height: 9rem;
  padding: 0;
  padding-top: 0.5rem;
  transition: all 0.3s ease;
  position: absolute;
  right: 0;
  top: 0;
  &:hover {
    background-color:darken($brand-primary,8%);
    transform: translateY(-0.5rem);
    .wrap-item {
      transform: none;
    }
  }

  @extend .bloc-clic;

  .figure {
    width: 6.8rem;
    height: 6.8rem;
    padding-top: 0.5rem;
    >img {
      max-width: 100%;
      height: auto;
    }
  }

  @media (max-width: 767px) {
    width: 8rem;
    height: 8rem;
    padding: 0.6rem;
    .figure {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      img {
        width: 4rem;
      }
    }
    .libelle {
      margin-top:0;
      line-height: 1rem;
    }
  }
}